define("discourse/plugins/discourse-learning-management/discourse/routes/learning", ["exports", "discourse/routes/discourse", "@ember/service"], function (_exports, _discourse, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    router: (0, _service.service)(),
    afterModel(model, transition) {
      if (transition.targetName === "learning.index") {
        this.router.transitionTo("learningCourse");
      }
    },
    actions: {
      showSettings() {
        const controller = this.controllerFor("adminSiteSettings");
        this.router.transitionTo("adminSiteSettingsCategory", "plugins").then(() => {
          controller.set("filter", "plugin:discourse-learning-management");
          controller.set("_skipBounce", true);
          controller.filterContentNow("plugins");
        });
      }
    }
  });
});