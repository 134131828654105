define("discourse/plugins/discourse-learning-management/discourse/components/reference-list", ["exports", "@ember/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label for="references">
    {{i18n "learning.references.title"}}
  </label>
  <ReferenceListItems
    @values={{this.referencesString}}
    @inputDelimiter={{this.inputDelimiter}}
    @onChange={{action "onChange"}}
  />
  */
  {
    "id": "ADY5DASY",
    "block": "[[[10,\"label\"],[14,\"for\",\"references\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"learning.references.title\"],null]],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,1],null,[[\"@values\",\"@inputDelimiter\",\"@onChange\"],[[30,0,[\"referencesString\"]],[30,0,[\"inputDelimiter\"]],[28,[37,2],[[30,0],\"onChange\"],null]]],null]],[],false,[\"i18n\",\"reference-list-items\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-learning-management/discourse/components/reference-list.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    inputDelimiter: "|",
    didReceiveAttrs() {
      const references = this.references;
      let referencesString = "";
      if (references) {
        referencesString = references.join(this.inputDelimiter);
      }
      this.set("referencesString", referencesString);
    },
    onChange(value) {
      this.set("references", value);
      this.set("referencesString", value.join(this.inputDelimiter));
    }
  }, [["method", "onChange", [_object.action]]])));
});