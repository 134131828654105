define("discourse/plugins/discourse-learning-management/discourse/models/learning-course", ["exports", "@ember/object", "discourse-common/utils/decorators", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/models/category"], function (_exports, _object, _decorators, _ajax, _ajaxError, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function snakeCase(string) {
    return string.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.toLowerCase()).join("_");
  }
  const LearningCourse = _object.default.extend(dt7948.p({
    category(categoryId) {
      return _category.default.findById(categoryId);
    },
    key(title) {
      if (!title || title.length < 3) {
        return null;
      } else {
        return snakeCase(title);
      }
    },
    save(opts) {
      let data = {
        course: {
          id: this.id,
          key: this.key,
          title: this.title,
          description: this.description,
          category_id: this.category_id,
          references: this.references,
          units: this.units
        }
      };
      if (opts.create) {
        data.course.id = "create";
      }
      return (0, _ajax.ajax)(`/learning/course/${data.course.id}`, {
        type: "PUT",
        contentType: "application/json",
        data: JSON.stringify(data)
      });
    },
    remove() {
      return (0, _ajax.ajax)(`/learning/course/${this.id}`, {
        type: "DELETE"
      }).then(() => this.destroy()).catch(_ajaxError.popupAjaxError);
    }
  }, [["method", "category", [(0, _decorators.default)("category_id")]], ["method", "key", [(0, _decorators.default)("title")]]]));
  LearningCourse.reopenClass({
    list() {
      return (0, _ajax.ajax)("/learning/course").catch(_ajaxError.popupAjaxError);
    },
    create(args) {
      args = args || {};
      args.units = (args.units || []).map((unit, ind) => {
        unit.index = ind;
        unit.steps = (unit.steps || []).map((step, i) => {
          step.index = i;
          return step;
        });
        return unit;
      });
      return this._super(args);
    }
  });
  var _default = _exports.default = LearningCourse;
});