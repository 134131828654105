define("discourse/plugins/discourse-learning-management/discourse/initializers/learning-topic", ["exports", "discourse-common/utils/decorators", "discourse/lib/keyboard-shortcuts", "discourse/lib/plugin-api", "discourse/plugins/discourse-learning-management/discourse/models/learning-unit", "discourse/models/user"], function (_exports, _decorators, _keyboardShortcuts, _pluginApi, _learningUnit, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "learning-topic",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.learning_management_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.12", api => {
        api.modifyClass("route:topic", {
          pluginId: "discourse-learning-management",
          isLearningUnit() {
            const controller = this.controllerFor("topic");
            const topic = controller.get("model");
            return topic.learningUnit;
          },
          actions: {
            didTransition() {
              if (this.isLearningUnit()) {
                _keyboardShortcuts.default.pause(["c"]);
                $("body").addClass("learning-unit");
              }
              return this._super(...arguments);
            },
            willTransition() {
              if (this.isLearningUnit()) {
                _keyboardShortcuts.default.unpause(["c"]);
                $("body").removeClass("learning-unit");
              }
              return this._super(...arguments);
            }
          }
        });
        api.modifyClass("model:topic", dt7948.p({
          pluginId: "discourse-learning-management",
          lastPostUrl(highestPostNumber, url, lastStepPostNumber) {
            return lastStepPostNumber ? this.urlForPostNumber(lastStepPostNumber) : this.urlForPostNumber(highestPostNumber);
          },
          suggestedTopics(suggestedTopics, learningUnit) {
            if (learningUnit) {
              return null;
            } else {
              return this._super(...arguments);
            }
          },
          learningUnit(learning_unit) {
            if (learning_unit) {
              return _learningUnit.default.create(Object.assign(learning_unit, {
                currentUser: _user.default.current()
              }));
            }
          }
        }, [["method", "lastPostUrl", [(0, _decorators.default)("highest_post_number", "url", "last_step_post_number")]], ["method", "suggestedTopics", [(0, _decorators.default)("suggested_topics", "learningUnit")]], ["method", "learningUnit", [(0, _decorators.default)("learning_unit")]]]));
        api.modifyClass("component:topic-progress", dt7948.p({
          pluginId: "discourse-learning-management",
          showBackButton(position, lastReadId, learningUnit) {
            if (learningUnit) {
              return false;
            } else {
              return this._super(...arguments);
            }
          }
        }, [["method", "showBackButton", [(0, _decorators.default)("progressPosition", "topic.last_read_post_id", "topic.learningUnit")]]]));
      });
    }
  };
});