define("discourse/plugins/discourse-learning-management/discourse/controllers/learning-course-show", ["exports", "@ember/controller", "@ember/runloop", "discourse/plugins/discourse-learning-management/discourse/models/learning-course"], function (_exports, _controller, _runloop, _learningCourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    success: false,
    error: null,
    loading: false,
    actions: {
      updateCategory(categoryId) {
        this.course.set("category_id", categoryId);
      },
      save() {
        this.setProperties({
          loading: true,
          error: null,
          success: false
        });
        const course = this.course;
        const creating = this.creating;
        let opts = {};
        if (creating) {
          opts.create = true;
        }
        course.save(opts).then(result => {
          if (result.errors) {
            this.set("error", result.errors);
          }
          if (result.course) {
            this.set("course", _learningCourse.default.create(result.course));
            this.set("success", true);
            (0, _runloop.later)(() => this.set("success", false), 10000);
          }
        }).catch(result => {
          this.set("error", result);
          (0, _runloop.later)(() => this.set("error", null), 10000);
        }).finally(() => this.setProperties({
          loading: false,
          creating: false
        }));
      },
      remove() {
        this.set("loading");
        this.course.remove().then(() => this.send("afterDestroy")).finally(() => this.set("loading", false));
      },
      goToCategory() {
        const url = this.course.category.url;
        window.open(url, "_blank").focus();
      }
    }
  });
});