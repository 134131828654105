define("discourse/plugins/discourse-learning-management/discourse/learning-management-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("learning", {
      path: "/learning",
      resetNamespace: true
    }, function () {
      this.route("learningCourse", {
        path: "/course/",
        resetNamespace: true
      }, function () {
        this.route("learningCourseShow", {
          path: "/:courseId/",
          resetNamespace: true
        });
      });
    });
  }
});