define("discourse/plugins/discourse-learning-management/discourse/components/learning-unit-topic-info", ["exports", "@glimmer/component", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LearningUnitTopicInfo extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @topic.learningUnit}}
          <div class="unit-meta">
            <span class="unit-number">
              {{i18n "unit.number" number=@topic.learningUnit.number}}
            </span>
            {{#if @topic.learningUnit.completed}}
              <span
                class="learning-completed.unit-completed.small"
                title={{i18n "unit.completed.title"}}
                >
                {{i18n "unit.completed.label"}}
              </span>
            {{/if}}
          </div>
        {{/if}}
      
    */
    {
      "id": "jMSQo83q",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"learningUnit\"]],[[[1,\"      \"],[10,0],[14,0,\"unit-meta\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"unit-number\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"unit.number\"],[[\"number\"],[[30,1,[\"learningUnit\",\"number\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[30,1,[\"learningUnit\",\"completed\"]],[[[1,\"          \"],[10,1],[14,0,\"learning-completed.unit-completed.small\"],[15,\"title\",[28,[32,0],[\"unit.completed.title\"],null]],[12],[1,\"\\n            \"],[1,[28,[32,0],[\"unit.completed.label\"],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@topic\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-learning-management/discourse/components/learning-unit-topic-info.js",
      "scope": () => [_i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LearningUnitTopicInfo;
});