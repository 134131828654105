define("discourse/plugins/discourse-learning-management/discourse/components/reference-list-items", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _object, _computed, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.collection}}
    <div class="values">
      {{#each this.collection as |value index|}}
        <div data-index={{index}} class="value">
          <DButton
            @action={{action "removeValue"}}
            @actionParam={{value}}
            @icon="times"
            @class="remove-value-btn btn-small"
          />
  
          <Input
            title={{value}}
            @value={{value}}
            class="value-input"
            {{on "focusout" (fn (action "changeValue") index)}}
          />
  
          {{#if this.showUpDownButtons}}
            <DButton
              @action={{action "shift" -1 index}}
              @icon="arrow-up"
              @class="shift-up-value-btn btn-small"
            />
            <DButton
              @action={{action "shift" 1 index}}
              @icon="arrow-down"
              @class="shift-down-value-btn btn-small"
            />
          {{/if}}
        </div>
      {{/each}}
    </div>
  {{/if}}
  
  <div class="reference-list-input">
    <Input
      @type="text"
      @value={{this.newValue}}
      placeholder={{i18n "learning.references.add_item"}}
      class="add-value-input"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
    />
  
    <DButton
      @action={{action "addValue"}}
      @actionParam={{this.newValue}}
      @disabled={{this.inputEmpty}}
      @icon="plus"
      @class="add-value-btn btn-small"
    />
  </div>
  */
  {
    "id": "CBH7rr/L",
    "block": "[[[41,[30,0,[\"collection\"]],[[[1,\"  \"],[10,0],[14,0,\"values\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"collection\"]]],null]],null],null,[[[1,\"      \"],[10,0],[15,\"data-index\",[30,2]],[14,0,\"value\"],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@action\",\"@actionParam\",\"@icon\",\"@class\"],[[28,[37,4],[[30,0],\"removeValue\"],null],[30,1],\"times\",\"remove-value-btn btn-small\"]],null],[1,\"\\n\\n        \"],[8,[39,5],[[16,\"title\",[30,1]],[24,0,\"value-input\"],[4,[38,6],[\"focusout\",[28,[37,7],[[28,[37,4],[[30,0],\"changeValue\"],null],[30,2]],null]],null]],[[\"@value\"],[[30,1]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"showUpDownButtons\"]],[[[1,\"          \"],[8,[39,3],null,[[\"@action\",\"@icon\",\"@class\"],[[28,[37,4],[[30,0],\"shift\",-1,[30,2]],null],\"arrow-up\",\"shift-up-value-btn btn-small\"]],null],[1,\"\\n          \"],[8,[39,3],null,[[\"@action\",\"@icon\",\"@class\"],[[28,[37,4],[[30,0],\"shift\",1,[30,2]],null],\"arrow-down\",\"shift-down-value-btn btn-small\"]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"reference-list-input\"],[12],[1,\"\\n  \"],[8,[39,5],[[16,\"placeholder\",[28,[37,8],[\"learning.references.add_item\"],null]],[24,0,\"add-value-input\"],[24,\"autocomplete\",\"off\"],[24,\"autocorrect\",\"off\"],[24,\"autocapitalize\",\"off\"]],[[\"@type\",\"@value\"],[\"text\",[30,0,[\"newValue\"]]]],null],[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@action\",\"@actionParam\",\"@disabled\",\"@icon\",\"@class\"],[[28,[37,4],[[30,0],\"addValue\"],null],[30,0,[\"newValue\"]],[30,0,[\"inputEmpty\"]],\"plus\",\"add-value-btn btn-small\"]],null],[1,\"\\n\"],[13]],[\"value\",\"index\"],false,[\"if\",\"each\",\"-track-array\",\"d-button\",\"action\",\"input\",\"on\",\"fn\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-learning-management/discourse/components/reference-list-items.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    classNameBindings: [":reference-list-items"],
    inputEmpty: (0, _computed.empty)("newValue"),
    inputDelimiter: null,
    newValue: "",
    collection: null,
    values: null,
    _setupCollection() {
      this.set("collection", this._splitValues(this.values, this.inputDelimiter));
    },
    keyDown(event) {
      if (event.which === 13) {
        this.addValue(this.newValue);
        return;
      }
    },
    changeValue(index, event) {
      this.collection.replace(index, 1, [event.target.value]);
      this.collection.arrayContentDidChange(index);
      this._onChange();
    },
    addValue(newValue) {
      if (this.inputEmpty) {
        return;
      }
      this.set("newValue", null);
      this.collection.addObject(newValue);
      this._onChange();
    },
    removeValue(value) {
      this.collection.removeObject(value);
      this._onChange();
    },
    shift(operation, index) {
      let futureIndex = index + operation;
      if (futureIndex > this.collection.length - 1) {
        futureIndex = 0;
      } else if (futureIndex < 0) {
        futureIndex = this.collection.length - 1;
      }
      const shiftedValue = this.collection[index];
      this.collection.removeAt(index);
      this.collection.insertAt(futureIndex, shiftedValue);
      this._onChange();
    },
    _onChange() {
      this.onChange?.(this.collection);
    },
    showUpDownButtons(collection) {
      return collection.length - 1 ? true : false;
    },
    _splitValues(values, delimiter) {
      return values && values.length ? values.split(delimiter || "\n").filter(Boolean) : [];
    }
  }, [["method", "_setupCollection", [(0, _decorators.on)("didReceiveAttrs")]], ["method", "changeValue", [_object.action]], ["method", "addValue", [_object.action]], ["method", "removeValue", [_object.action]], ["method", "shift", [_object.action]], ["method", "showUpDownButtons", [(0, _decorators.default)("collection")]]])));
});