define("discourse/plugins/discourse-learning-management/discourse/models/learning-unit", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators"], function (_exports, _object, _ajax, _ajaxError, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LearningUnit = _object.default.extend(dt7948.p({
    canComplete(canUpdateUserSteps, completedSelfReview) {
      return canUpdateUserSteps && !completedSelfReview;
    },
    completeStep(step) {
      return this.userStep(step, "PUT");
    },
    uncompleteStep(step) {
      return this.userStep(step, "DELETE");
    },
    completeSelfReview() {
      return this.userStep("self", "PUT");
    },
    submitStepResponse(step, postId) {
      return this.userStep(step, "PUT", {
        post_id: postId
      }, "response");
    },
    unsubmitStepResponse(step, postId) {
      return this.userStep(step, "DELETE", {
        post_id: postId
      }, "response");
    },
    markStep(step, mark, postId) {
      return this.userStep(step, "PUT", {
        mark,
        post_id: postId
      }, "mark");
    },
    forceStepVisible(step) {
      return this.userStep(step, "PUT", {}, "force-visible");
    },
    userStep(step, type) {
      let data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      let path = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      if (!this.currentUser || !step) {
        return;
      }
      let url = `/learning/unit/${this.id}/${step}/${this.currentUser.id}`;
      if (path) {
        url += `/${path}`;
      }
      return (0, _ajax.ajax)(url, {
        type,
        data
      }).catch(_ajaxError.popupAjaxError);
    }
  }, [["method", "canComplete", [(0, _decorators.default)("can_update_user_steps", "completed_self_review")]]]));
  var _default = _exports.default = LearningUnit;
});