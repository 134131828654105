define("discourse/plugins/discourse-learning-management/discourse/templates/connectors/before-topic-list-body/units-category-topic-list-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LearningUnitListHeader />
  */
  {
    "id": "JPEaHPL5",
    "block": "[[[8,[39,0],null,null,null]],[],false,[\"learning-unit-list-header\"]]",
    "moduleName": "discourse/plugins/discourse-learning-management/discourse/templates/connectors/before-topic-list-body/units-category-topic-list-header.hbs",
    "isStrictMode": false
  });
});