define("discourse/plugins/discourse-learning-management/discourse/templates/learning-course", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-learning-controls">
    {{combo-box
      value=coursesVal
      content=courses
      onChange=(route-action "changeCourse")
      options=(hash
        none="learning.course.select"
      )}}
  
    {{d-button
      action=(route-action "createCourse")
      label="learning.course.create"
      icon="plus"}}
  </div>
  
  <div class="admin-learning-container settings">
    {{outlet}}
  </div>
  
  */
  {
    "id": "bAmJXRuz",
    "block": "[[[10,0],[14,0,\"admin-learning-controls\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"value\",\"content\",\"onChange\",\"options\"],[[30,0,[\"coursesVal\"]],[30,0,[\"courses\"]],[28,[37,1],[\"changeCourse\"],null],[28,[37,2],null,[[\"none\"],[\"learning.course.select\"]]]]]]],[1,\"\\n\\n  \"],[1,[28,[35,3],null,[[\"action\",\"label\",\"icon\"],[[28,[37,1],[\"createCourse\"],null],\"learning.course.create\",\"plus\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-learning-container settings\"],[12],[1,\"\\n  \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `coursesVal` property path was used in the `discourse/plugins/discourse-learning-management/discourse/templates/learning-course.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.coursesVal}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `courses` property path was used in the `discourse/plugins/discourse-learning-management/discourse/templates/learning-course.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.courses}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"combo-box\",\"route-action\",\"hash\",\"d-button\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-learning-management/discourse/templates/learning-course.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});