define("discourse/plugins/discourse-learning-management/discourse/templates/connectors/discovery-list-container-top/units-category-description-container", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if category.learning_units}}
    <div class="units-category-description">
      {{{category.description}}}
    </div>
  {{/if}}
  */
  {
    "id": "05M+VWgA",
    "block": "[[[41,[30,0,[\"category\",\"learning_units\"]],[[[1,\"  \"],[10,0],[14,0,\"units-category-description\"],[12],[1,\"\\n    \"],[2,[30,0,[\"category\",\"description\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `category` property path was used in the `discourse/plugins/discourse-learning-management/discourse/templates/connectors/discovery-list-container-top/units-category-description-container.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.category}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `category` property path was used in the `discourse/plugins/discourse-learning-management/discourse/templates/connectors/discovery-list-container-top/units-category-description-container.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.category}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\"]]",
    "moduleName": "discourse/plugins/discourse-learning-management/discourse/templates/connectors/discovery-list-container-top/units-category-description-container.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});