define("discourse/plugins/discourse-learning-management/discourse/connectors/after-topic-footer-main-buttons/complete-unit-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(attrs) {
      return attrs.topic.learningUnit;
    }
  };
});