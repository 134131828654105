define("discourse/plugins/discourse-learning-management/discourse/initializers/learning-composer", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "discourse/models/composer", "@ember/object", "@ember/template", "I18n"], function (_exports, _pluginApi, _decorators, _composer, _object, _template, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "learning-composer",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.learning_management_enabled) {
        return;
      }
      function getLearningComposerKey(action, composerModel) {
        let key;
        let post = composerModel.post;
        if (action === _composer.CREATE_TOPIC) {
          key = "create_unit";
        } else if (action === _composer.REPLY && post) {
          key = post.comment ? "reply_to_comment" : "create_comment";
        } else if (action === _composer.EDIT && post) {
          key = post.comment ? "edit_comment" : "edit_step";
        } else {
          key = "create_step";
        }
        return key;
      }
      function getLearningComposerText(type, composerModel) {
        let icon = "reply";
        let post = composerModel.post;
        if (type === "create_comment") {
          icon = "comment";
        } else if (type === "create_unit") {
          icon = "plus";
        } else if (["edit_step", "edit_comment"].includes(type)) {
          icon = "pencil-alt";
        }
        let opts = {};
        if (post && post.learningStep) {
          opts.stepNumber = post.learningStep.number;
          opts.stepTitle = post.learningStep.title;
          opts.stepUrl = post.url;
        }
        return {
          icon,
          name: `composer.text.${type}.name`,
          action: `composer.text.${type}.action`,
          description: `composer.text.${type}.description`,
          opts
        };
      }
      function learningComposer(category, post) {
        return category && category.learning_course || post && post.topic.learning_unit;
      }
      (0, _pluginApi.withPluginApi)("0.8.12", api => {
        api.modifyClass("controller:composer", dt7948.p({
          pluginId: "discourse-learning-management",
          open(opts) {
            if (opts.topic && opts.topic.learning_unit && opts.quote && !opts.post) {
              opts.post = opts.topic.postStream.posts[0];
            }
            return this._super(opts);
          },
          isLearning(category, post) {
            return learningComposer(category, post);
          },
          learningComposerText(action) {
            let key = getLearningComposerKey(action, this.model);
            return getLearningComposerText(key, this.model);
          },
          saveLabel(modelAction, isWhispering, editConflict, isLearning, learningLabel) {
            if (isLearning) {
              return learningLabel;
            } else {
              return this._super(...arguments);
            }
          },
          saveIcon(modelAction, isWhispering, isLearning, learningIcon) {
            if (isLearning) {
              return learningIcon;
            } else {
              return this._super(...arguments);
            }
          }
        }, [["method", "isLearning", [(0, _decorators.default)("model.category", "model.post")]], ["method", "learningComposerText", [(0, _decorators.default)("model.action", "model.post")]], ["method", "saveLabel", [(0, _decorators.default)("model.action", "isWhispering", "model.editConflict", "isLearning", "learningComposerText.name")]], ["method", "saveIcon", [(0, _decorators.default)("model.action", "isWhispering", "isLearning", "learningComposerText.icon")]]]));
        api.modifyClass("component:composer-action-title", dt7948.p({
          pluginId: "discourse-learning-management",
          actionTitle(opts, action, category, post) {
            let key = getLearningComposerKey(action, this.model);
            let text = getLearningComposerText(key, this.model);
            if (learningComposer(category, post) && text) {
              return (0, _template.htmlSafe)(_I18n.default.t(text.action, text.opts));
            } else {
              return this._super(...arguments);
            }
          }
        }, [["method", "actionTitle", [(0, _decorators.default)("options", "action", "model.category", "model.post")]]]));
        api.modifyClass("component:composer-actions", {
          pluginId: "discourse-learning-management",
          didReceiveAttrs() {
            const composer = this.get("composerModel");
            if (composer) {
              this.setProperties({
                postSnapshot: composer.post,
                isLearning: learningComposer(composer.category, composer.post)
              });
            }
            this._super(...arguments);
          },
          learningKey: (0, _object.computed)("isLearning", function () {
            return getLearningComposerKey(this.action, this.composerModel);
          }),
          iconForComposerAction: (0, _object.computed)("action", "isLearning", function () {
            if (this.isLearning) {
              return getLearningComposerText(this.learningKey, this.composerModel).icon;
            } else {
              return this._super(...arguments);
            }
          }),
          content: (0, _object.computed)("seq", "isLearning", function () {
            if (this.isLearning) {
              const text = getLearningComposerText(this.learningKey, this.composerModel);
              return [{
                id: "reply_to_post",
                icon: text.icon,
                name: _I18n.default.t(text.name),
                description: _I18n.default.t(text.description)
              }];
            } else {
              return this._super(...arguments);
            }
          })
        });
      });
    }
  };
});