define("discourse/plugins/discourse-learning-management/discourse/connectors/before-topic-list-body/units-category-topic-list-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(_, ctx) {
      return ctx.siteSettings.learning_management_enabled;
    }
  };
});