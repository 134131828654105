define("discourse/plugins/discourse-learning-management/discourse/initializers/learning-sidebar", ["exports", "discourse/lib/plugin-api", "I18n", "discourse/models/category", "discourse/lib/sidebar/custom-sections", "@ember/application"], function (_exports, _pluginApi, _I18n, _category, _customSections, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "learning-sidebar",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      const siteSettings = container.lookup("service:site-settings");
      if (!currentUser || !siteSettings.learning_management_enabled) {
        return;
      }
      const site = container.lookup("service:site");
      const userGroups = currentUser.groups.map(g => g.name);
      const learningGroups = site.get("learning_management_allowed_groups_names");
      (0, _pluginApi.withPluginApi)("1.6.0", api => {
        api.addSidebarSection((BaseCustomSidebarSection, BaseCustomSidebarSectionLink) => {
          return class extends BaseCustomSidebarSection {
            get name() {
              return "learning";
            }
            get title() {
              return _I18n.default.t("learning.nav.title");
            }
            get text() {
              return _I18n.default.t("learning.nav.label");
            }
            get links() {
              const sidebarLinks = [];
              const learningCategories = site.categories.filter(c => c.learning_course_parent);
              if (learningGroups && userGroups && userGroups.filter(g => learningGroups.includes(g)).length > 0) {
                sidebarLinks.push(new class extends BaseCustomSidebarSectionLink {
                  get name() {
                    return "learning";
                  }
                  get route() {
                    return "learning";
                  }
                  get title() {
                    return _I18n.default.t("learning.admin.title");
                  }
                  get text() {
                    return _I18n.default.t("learning.admin.label");
                  }
                  get prefixType() {
                    return "icon";
                  }
                  get prefixValue() {
                    return "wrench";
                  }
                }());
              }
              learningCategories.forEach(lc => {
                sidebarLinks.push(new class extends BaseCustomSidebarSectionLink {
                  get name() {
                    return "learning-course";
                  }
                  get route() {
                    return "discovery.category";
                  }
                  get model() {
                    return `${_category.default.slugFor(lc)}/${lc.id}`;
                  }
                  get title() {
                    return lc.name;
                  }
                  get text() {
                    return lc.name;
                  }
                  get prefixType() {
                    return "icon";
                  }
                  get prefixValue() {
                    return "book";
                  }
                }());
              });
              return sidebarLinks;
            }
          };
        });
        api.modifyClass("component:sidebar/user/sections", {
          pluginId: 'discourse-learning-management',
          get _customSections() {
            return _customSections.customSections.map(customSection => {
              const section = new customSection({
                sidebar: this
              });
              (0, _application.setOwner)(section, (0, _application.getOwner)(this));
              return section;
            }).sort(a => {
              return a.name.includes("chat") ? 1 : -1;
            });
          }
        });
      });
    }
  };
});