define("discourse/plugins/discourse-learning-management/discourse/raw-templates/list/unit-list-item", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "1": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return container.escapeExpression((lookupProperty(helpers, "topic-featured-link") || depth0 && lookupProperty(depth0, "topic-featured-link") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "topic", {
        "name": "topic-featured-link",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 14,
            "column": 4
          },
          "end": {
            "line": 14,
            "column": 34
          }
        }
      }));
    },
    "3": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return container.escapeExpression((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "topic-post-badges", {
        "name": "raw",
        "hash": {
          "newDotText": "newDotText",
          "url": "topic.lastUnreadUrl",
          "unseen": "topic.unseen",
          "unreadPosts": "topic.unread_posts"
        },
        "hashTypes": {
          "newDotText": "PathExpression",
          "url": "PathExpression",
          "unseen": "PathExpression",
          "unreadPosts": "PathExpression"
        },
        "hashContexts": {
          "newDotText": depth0,
          "url": depth0,
          "unseen": depth0,
          "unreadPosts": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 21,
            "column": 4
          },
          "end": {
            "line": 21,
            "column": 129
          }
        }
      }));
    },
    "5": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return (stack1 = lookupProperty(helpers, "unless").call(depth0 != null ? depth0 : container.nullContext || {}, "topic.isPinnedUncategorized", {
        "name": "unless",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(6, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 26,
            "column": 6
          },
          "end": {
            "line": 28,
            "column": 17
          }
        }
      })) != null ? stack1 : "";
    },
    "6": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "        " + container.escapeExpression((lookupProperty(helpers, "category-link") || depth0 && lookupProperty(depth0, "category-link") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "topic.category", {
        "name": "category-link",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 27,
            "column": 8
          },
          "end": {
            "line": 27,
            "column": 40
          }
        }
      })) + "\n";
    },
    "8": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "    " + container.escapeExpression((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "list.topic-excerpt", {
        "name": "raw",
        "hash": {
          "topic": "topic"
        },
        "hashTypes": {
          "topic": "PathExpression"
        },
        "hashContexts": {
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 34,
            "column": 4
          },
          "end": {
            "line": 34,
            "column": 44
          }
        }
      })) + "\n";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        alias3 = container.hooks.helperMissing,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<td class=\"num unit\">\n  <a href='" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.summaryUrl", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 2,
            "column": 11
          },
          "end": {
            "line": 2,
            "column": 31
          }
        }
      })) + "'>\n    " + alias2((lookupProperty(helpers, "number") || depth0 && lookupProperty(depth0, "number") || alias3).call(alias1, "topic.unit_number", {
        "name": "number",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 4
          },
          "end": {
            "line": 3,
            "column": 32
          }
        }
      })) + "\n  </a>\n</td>\n\n<td class='main-link clearfix' colspan=\"1\">" + alias2((lookupProperty(helpers, "raw-plugin-outlet") || depth0 && lookupProperty(depth0, "raw-plugin-outlet") || alias3).call(alias1, {
        "name": "raw-plugin-outlet",
        "hash": {
          "name": "topic-list-before-link"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 8,
            "column": 2
          },
          "end": {
            "line": 8,
            "column": 54
          }
        }
      })) + "\n  <span class='link-top-line'>" + alias2((lookupProperty(helpers, "raw-plugin-outlet") || depth0 && lookupProperty(depth0, "raw-plugin-outlet") || alias3).call(alias1, {
        "name": "raw-plugin-outlet",
        "hash": {
          "name": "topic-list-before-status"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 10,
            "column": 4
          },
          "end": {
            "line": 10,
            "column": 58
          }
        }
      })) + alias2((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias3).call(alias1, "topic-status", {
        "name": "raw",
        "hash": {
          "topic": "topic"
        },
        "hashTypes": {
          "topic": "PathExpression"
        },
        "hashContexts": {
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 11,
            "column": 4
          },
          "end": {
            "line": 11,
            "column": 39
          }
        }
      })) + alias2((lookupProperty(helpers, "topic-link") || depth0 && lookupProperty(depth0, "topic-link") || alias3).call(alias1, "topic", {
        "name": "topic-link",
        "hash": {
          "class": "raw-link raw-topic-link"
        },
        "hashTypes": {
          "class": "StringLiteral"
        },
        "hashContexts": {
          "class": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 12,
            "column": 4
          },
          "end": {
            "line": 12,
            "column": 57
          }
        }
      })) + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.featured_link", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 13,
            "column": 4
          },
          "end": {
            "line": 15,
            "column": 12
          }
        }
      })) != null ? stack1 : "") + alias2((lookupProperty(helpers, "raw-plugin-outlet") || depth0 && lookupProperty(depth0, "raw-plugin-outlet") || alias3).call(alias1, {
        "name": "raw-plugin-outlet",
        "hash": {
          "name": "topic-list-after-title"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 16,
            "column": 4
          },
          "end": {
            "line": 16,
            "column": 56
          }
        }
      })) + alias2((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias3).call(alias1, "list.unread-indicator", {
        "name": "raw",
        "hash": {
          "unreadClass": "unreadClass",
          "topicId": "topic.id",
          "includeUnreadIndicator": "includeUnreadIndicator"
        },
        "hashTypes": {
          "unreadClass": "PathExpression",
          "topicId": "PathExpression",
          "includeUnreadIndicator": "PathExpression"
        },
        "hashContexts": {
          "unreadClass": depth0,
          "topicId": depth0,
          "includeUnreadIndicator": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 17,
            "column": 4
          },
          "end": {
            "line": 19,
            "column": 61
          }
        }
      })) + ((stack1 = lookupProperty(helpers, "if").call(alias1, "showTopicPostBadges", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(3, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 20,
            "column": 4
          },
          "end": {
            "line": 22,
            "column": 12
          }
        }
      })) != null ? stack1 : "") + "  </span>\n  <div class=\"link-bottom-line\">\n" + ((stack1 = lookupProperty(helpers, "unless").call(alias1, "hideCategory", {
        "name": "unless",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(5, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 25,
            "column": 4
          },
          "end": {
            "line": 29,
            "column": 15
          }
        }
      })) != null ? stack1 : "") + "    " + alias2((lookupProperty(helpers, "discourse-tags") || depth0 && lookupProperty(depth0, "discourse-tags") || alias3).call(alias1, "topic", {
        "name": "discourse-tags",
        "hash": {
          "tagsForUser": "tagsForUser",
          "mode": "list"
        },
        "hashTypes": {
          "tagsForUser": "PathExpression",
          "mode": "StringLiteral"
        },
        "hashContexts": {
          "tagsForUser": depth0,
          "mode": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 30,
            "column": 4
          },
          "end": {
            "line": 30,
            "column": 64
          }
        }
      })) + "\n    " + alias2((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias3).call(alias1, "list.action-list", {
        "name": "raw",
        "hash": {
          "icon": "heart",
          "className": "likes",
          "postNumbers": "topic.liked_post_numbers",
          "topic": "topic"
        },
        "hashTypes": {
          "icon": "StringLiteral",
          "className": "StringLiteral",
          "postNumbers": "PathExpression",
          "topic": "PathExpression"
        },
        "hashContexts": {
          "icon": depth0,
          "className": depth0,
          "postNumbers": depth0,
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 31,
            "column": 4
          },
          "end": {
            "line": 31,
            "column": 110
          }
        }
      })) + "\n  </div>\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "expandPinned", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(8, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 33,
            "column": 2
          },
          "end": {
            "line": 35,
            "column": 9
          }
        }
      })) != null ? stack1 : "") + "</td>\n\n" + alias2((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias3).call(alias1, "list.posters-column", {
        "name": "raw",
        "hash": {
          "posters": "topic.featuredUsers"
        },
        "hashTypes": {
          "posters": "PathExpression"
        },
        "hashContexts": {
          "posters": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 38,
            "column": 0
          },
          "end": {
            "line": 38,
            "column": 57
          }
        }
      })) + "\n\n<td class=\"num steps\">\n  <a href='" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.summaryUrl", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 41,
            "column": 11
          },
          "end": {
            "line": 41,
            "column": 31
          }
        }
      })) + "'>\n    <span class=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.learning_steps_class", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 42,
            "column": 17
          },
          "end": {
            "line": 42,
            "column": 47
          }
        }
      })) + "\" title=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.learning_steps_title", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 42,
            "column": 56
          },
          "end": {
            "line": 42,
            "column": 86
          }
        }
      })) + "\">" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.learning_steps_label", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 42,
            "column": 88
          },
          "end": {
            "line": 42,
            "column": 118
          }
        }
      })) + "</span>\n  </a>\n</td>\n\n<td class=\"num comments\">\n  <a href='" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.summaryUrl", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 47,
            "column": 11
          },
          "end": {
            "line": 47,
            "column": 31
          }
        }
      })) + "'>\n    " + alias2((lookupProperty(helpers, "number") || depth0 && lookupProperty(depth0, "number") || alias3).call(alias1, "topic.comment_count", {
        "name": "number",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 48,
            "column": 4
          },
          "end": {
            "line": 48,
            "column": 34
          }
        }
      })) + "\n  </a>\n</td>\n\n<td class=\"num views " + alias2(lookupProperty(helpers, "get").call(alias1, "topic.viewsHeat", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 52,
            "column": 21
          },
          "end": {
            "line": 52,
            "column": 40
          }
        }
      })) + "\">" + alias2((lookupProperty(helpers, "number") || depth0 && lookupProperty(depth0, "number") || alias3).call(alias1, "topic.views", {
        "name": "number",
        "hash": {
          "numberKey": "views_long"
        },
        "hashTypes": {
          "numberKey": "StringLiteral"
        },
        "hashContexts": {
          "numberKey": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 52,
            "column": 42
          },
          "end": {
            "line": 52,
            "column": 87
          }
        }
      })) + "</td>\n\n" + alias2((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias3).call(alias1, "list.activity-column", {
        "name": "raw",
        "hash": {
          "tagName": "td",
          "class": "num",
          "topic": "topic"
        },
        "hashTypes": {
          "tagName": "StringLiteral",
          "class": "StringLiteral",
          "topic": "PathExpression"
        },
        "hashContexts": {
          "tagName": depth0,
          "class": depth0,
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 54,
            "column": 0
          },
          "end": {
            "line": 54,
            "column": 67
          }
        }
      })) + "\n";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/list/unit-list-item", template, {
    core: true
  });
  var _default = _exports.default = template;
});