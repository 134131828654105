define("discourse/plugins/discourse-learning-management/discourse/components/learning-unit-list-header", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/raw", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _raw, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LearningUnitListHeader extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "discovery", [_service.service]))();
    #discovery = (() => (dt7948.i(this, "discovery"), void 0))();
    get category() {
      return this.discovery?.category;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.category.learning_units}}
          <thead>
            {{raw "unit-list-header"}}
          </thead>
        {{/if}}
      
    */
    {
      "id": "2RqB7xGB",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"category\",\"learning_units\"]],[[[1,\"      \"],[10,\"thead\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"unit-list-header\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-learning-management/discourse/components/learning-unit-list-header.js",
      "scope": () => [_raw.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LearningUnitListHeader;
});