define("discourse/plugins/discourse-learning-management/discourse/templates/learning", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h2 class="learning-management-title">{{i18n "learning.admin.title"}}</h2>
  
  <div class="learning-management-controls">
    <nav>
      <ul class="nav nav-pills">
        {{nav-item route="learningCourse" label="learning.course.nav_label"}}
  
        {{#if currentUser.admin}}
          <li class="learning-management-settings">
            <DButton
              @class="btn-default"
              @action={{route-action "showSettings"}}
              @icon="cog"
              @label="admin.plugins.change_settings_short"
            />
          </li>
        {{/if}}
      </ul>
    </nav>
  </div>
  
  <div class="learning-management-container">
    {{outlet}}
  </div>
  */
  {
    "id": "jf0ZNRrq",
    "block": "[[[10,\"h2\"],[14,0,\"learning-management-title\"],[12],[1,[28,[35,0],[\"learning.admin.title\"],null]],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"learning-management-controls\"],[12],[1,\"\\n  \"],[10,\"nav\"],[12],[1,\"\\n    \"],[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n      \"],[1,[28,[35,1],null,[[\"route\",\"label\"],[\"learningCourse\",\"learning.course.nav_label\"]]]],[1,\"\\n\\n\"],[41,[30,0,[\"currentUser\",\"admin\"]],[[[1,\"        \"],[10,\"li\"],[14,0,\"learning-management-settings\"],[12],[1,\"\\n          \"],[8,[39,3],null,[[\"@class\",\"@action\",\"@icon\",\"@label\"],[\"btn-default\",[28,[37,4],[\"showSettings\"],null],\"cog\",\"admin.plugins.change_settings_short\"]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"learning-management-container\"],[12],[1,\"\\n  \"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `currentUser` property path was used in the `discourse/plugins/discourse-learning-management/discourse/templates/learning.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.currentUser}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"i18n\",\"nav-item\",\"if\",\"d-button\",\"route-action\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-learning-management/discourse/templates/learning.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});