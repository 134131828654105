define("discourse/plugins/discourse-learning-management/discourse/routes/learning-course-show", ["exports", "discourse/plugins/discourse-learning-management/discourse/models/learning-course", "discourse/lib/ajax", "discourse/routes/discourse", "@ember/service"], function (_exports, _learningCourse, _ajax, _discourse, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    router: (0, _service.service)(),
    model(params) {
      if (params.courseId === "create") {
        return {
          create: true
        };
      } else {
        return (0, _ajax.ajax)(`/learning/course/${params.courseId}`);
      }
    },
    afterModel(model) {
      if (model.none) {
        return this.router.transitionTo("learningCourse");
      }
    },
    setupController(controller, model) {
      const course = _learningCourse.default.create(!model || model.create ? {} : model.course);
      let props = {
        course,
        creating: model.create
      };
      if (course.units) {
        props.currentUnit = course.units[0];
      }
      controller.setProperties(props);
    }
  });
});