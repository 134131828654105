define("discourse/plugins/discourse-learning-management/discourse/widgets/timeline-steps", ["discourse/widgets/widget", "virtual-dom", "I18n"], function (_widget, _virtualDom, _I18n) {
  "use strict";

  (0, _widget.createWidget)("timeline-steps", {
    tagName: "div.timeline-steps",
    html(attrs) {
      const {
        topic,
        current
      } = attrs;
      const $scrollArea = $(".timeline-scrollarea");
      const scrollAreaHeight = $scrollArea.height();
      const stepPostIds = topic.step_post_ids;
      const completedStepPostIds = topic.completed_step_post_ids;
      const postStream = topic.postStream;
      const stream = postStream.get("stream");
      const totalPosts = postStream.get("filteredPostsCount");
      const count = stepPostIds.length;
      let contents = [this.attach("link", {
        className: "steps-title",
        rawLabel: `${count} ${_I18n.default.t("step_lowercase", {
          count
        })}`,
        action: "jumpTop"
      })];
      let stepList = [];
      stepPostIds.forEach((postId, index) => {
        let stepNumber = index + 1;
        let currentStep = stream.indexOf(postId) + 1;
        let stepPercent = currentStep / totalPosts;
        let position = Math.floor(stepPercent * scrollAreaHeight);
        let post = postStream.posts.find(p => p.id === postId);
        let completed = completedStepPostIds && completedStepPostIds.includes(postId);
        stepList.push({
          stepNumber,
          current,
          currentStep,
          position,
          scrollAreaHeight,
          post,
          completed
        });
      });
      contents.push((0, _virtualDom.h)(".step-list", stepList.map((marker, index) => {
        return this.attach("step-marker", Object.assign(marker, {
          even: index % 2 === 0
        }));
      })));
      return contents;
    }
  });
  const buffer = 4;
  const markerHeight = 5;
  (0, _widget.createWidget)("step-marker", {
    tagName: "div",
    buildKey: attrs => `step-marker-${attrs.stepNumber}`,
    buildAttributes(attrs) {
      const {
        position,
        stepNumber
      } = attrs;
      return {
        style: `top: ${position - buffer}px; height: ${markerHeight}px`,
        title: _I18n.default.t("topic.step.jump_to", {
          stepNumber
        })
      };
    },
    buildClasses(attrs) {
      let classes = "step-marker";
      if (attrs.current === attrs.currentStep) {
        classes += " active";
      }
      if (attrs.completed) {
        classes += " completed";
      }
      classes += attrs.even ? " even" : " odd";
      return classes;
    },
    html(attrs) {
      return (0, _virtualDom.h)("span", `${attrs.stepNumber}`);
    },
    click(e) {
      const percent = $(e.target).offset().top - markerHeight / 2 + buffer;
      this.sendWidgetAction("updatePercentage", percent);
      this.sendWidgetAction("commit");
    }
  });
});