define("discourse/plugins/discourse-learning-management/discourse/routes/learning-course", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-learning-management/discourse/models/learning-course", "@ember/array", "@ember/service"], function (_exports, _discourse, _learningCourse, _array, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    router: (0, _service.service)(),
    model() {
      return _learningCourse.default.list();
    },
    setupController(controller, model) {
      let props = {
        courses: (0, _array.A)(model.courses.map(c => {
          return {
            id: c.id,
            name: c.title
          };
        })),
        courseId: this.currentCourseId()
      };
      controller.setProperties(props);
    },
    currentCourseId() {
      const params = this.paramsFor("learningCourseShow");
      if (params && params.courseId && params.courseId !== "create") {
        return Number(params.courseId);
      } else {
        return null;
      }
    },
    actions: {
      changeCourse(courseId) {
        this.controllerFor("learningCourse").set("courseId", courseId);
        if (courseId) {
          this.router.transitionTo("learningCourseShow", courseId);
        } else {
          this.router.transitionTo("learningCourse");
        }
      },
      afterDestroy() {
        this.router.transitionTo("learningCourse").then(() => this.refresh());
      },
      afterSave(courseId) {
        this.refresh().then(() => this.send("changeCourse", courseId));
      },
      createCourse() {
        this.controllerFor("learningCourse").set("courseId", "create");
        this.router.transitionTo("learningCourseShow", "create");
      }
    }
  });
});