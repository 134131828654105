define("discourse/plugins/discourse-learning-management/discourse/initializers/learning-discovery", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "discourse-common/lib/raw-templates", "discourse-common/lib/raw-handlebars-helpers", "@ember/service", "@ember/template"], function (_exports, _pluginApi, _decorators, _rawTemplates, _rawHandlebarsHelpers, _service, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "learning-discovery",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.learning_management_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.12", api => {
        api.modifyClass("component:topic-list", dt7948.p({
          pluginId: "discourse-learning-management",
          skipHeader(learningUnits) {
            return learningUnits || this._super(...arguments);
          }
        }, [["method", "skipHeader", [(0, _decorators.default)("category.learning_units")]]]));
        api.modifyClass("component:topic-list-item", dt7948.p({
          pluginId: "discourse-learning-management",
          discovery: (0, _service.service)(),
          renderTopicListItem() {
            const category = this.discovery.category;
            if (category?.learning_units) {
              const template = (0, _rawTemplates.findRawTemplate)("list/unit-list-item");
              if (template) {
                this.set("topicListItemContents", (0, _template.htmlSafe)(template(this, _rawHandlebarsHelpers.RUNTIME_OPTIONS)));
              }
            } else {
              return this._super(...arguments);
            }
          }
        }, [["method", "renderTopicListItem", [(0, _decorators.observes)("topic.pinned")]]]));
        api.modifyClass("route:discovery", {
          pluginId: "discourse-learning-management",
          actions: {
            didTransition() {
              const category = this.controllerFor("discovery/list").get("model.category");
              if (category?.learning_course) {
                $("body").addClass("learning-course");
                if (category.learning_units) {
                  $("body").addClass("learning-units");
                } else {
                  $("body").removeClass("learning-units");
                }
              } else {
                $("body").removeClass("learning-course");
                $("body").removeClass("learning-units");
              }
              return this._super();
            }
          }
        });
      });
    }
  };
});