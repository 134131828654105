define("discourse/plugins/discourse-learning-management/discourse/controllers/learning-course", ["exports", "@ember/controller", "discourse-common/utils/decorators", "@ember/object/computed"], function (_exports, _controller, _decorators, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    creating: (0, _computed.equal)("courseId", "create"),
    coursesVal(creating, courseId) {
      return creating ? null : courseId;
    }
  }, [["method", "coursesVal", [(0, _decorators.default)("creating", "courseId")]]]));
});