define("discourse/plugins/discourse-learning-management/discourse/templates/connectors/header-topic-info__after/learning-unit-topic-info-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LearningUnitTopicInfo @topic={{@topic}} />
  */
  {
    "id": "Eb/VbLv/",
    "block": "[[[8,[39,0],null,[[\"@topic\"],[[30,1]]],null]],[\"@topic\"],false,[\"learning-unit-topic-info\"]]",
    "moduleName": "discourse/plugins/discourse-learning-management/discourse/templates/connectors/header-topic-info__after/learning-unit-topic-info-container.hbs",
    "isStrictMode": false
  });
});