define("discourse/plugins/discourse-learning-management/discourse/initializers/learning-post", ["exports", "discourse-common/utils/decorators", "discourse/lib/posts-with-placeholders", "discourse/lib/plugin-api", "virtual-dom", "@ember/runloop", "@ember/object/computed", "@ember/object", "@ember/application", "I18n"], function (_exports, _decorators, _postsWithPlaceholders, _pluginApi, _virtualDom, _runloop, _computed, _object, _application, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "learning-post",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.learning_management_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.12", api => {
        const store = api.container.lookup("store:main");
        const currentUser = api.getCurrentUser();
        api.includePostAttributes("learning_unit", "learningStep", "reply_to_post_number", "comment", "showComment", "step_post_ids", "can_submit_as_response");
        api.modifyClass("model:post", dt7948.p({
          pluginId: "discourse-learning-management",
          learningStep(learning_step) {
            if (learning_step) {
              return _object.default.create(learning_step);
            } else {
              return null;
            }
          }
        }, [["method", "learningStep", [(0, _decorators.default)("learning_step")]]]));
        api.reopenWidget("post-menu", {
          menuItems() {
            let result = this._super();
            if (this.attrs.learningStep || this.attrs.firstPost) {
              result = result.filter(b => b !== "reply");
            }
            return result;
          },
          completeStep() {
            const post = this.findAncestorModel();
            const topic = post.topic;
            const learningStep = post.learningStep;
            const learningUnit = topic.learningUnit;
            if (!learningUnit || !learningStep) {
              return null;
            }
            learningUnit.completeStep(learningStep.number).then(result => {
              if (result && result.success) {
                this.handleSelfCompletionResult(result);
              }
            });
          },
          uncompleteStep() {
            const post = this.findAncestorModel();
            const topic = post.topic;
            const learningStep = post.learningStep;
            const learningUnit = topic.learningUnit;
            if (!learningUnit || !learningStep) {
              return null;
            }
            learningUnit.uncompleteStep(learningStep.number).then(result => {
              if (result && result.success) {
                this.handleSelfCompletionResult(result);
              }
            });
          },
          handleSelfCompletionResult(result) {
            const post = this.findAncestorModel();
            const learningStep = post.learningStep;
            const topic = post.topic;
            const learningUnit = post.topic.learningUnit;
            learningStep.setProperties({
              completed: !!result.completed_at,
              completed_at: result.completed_at
            });
            topic.set("completed_step_count", result.completed_step_count);
            const unitCompleted = topic.completed_step_count === learningUnit.step_count;
            if (learningUnit.completed !== unitCompleted) {
              learningUnit.setProperties({
                completed: !!result.completed_at,
                completed_at: result.completed_at
              });
              this.appEvents.trigger("header:update-topic", topic);
            }
            this.appEvents.trigger("post-stream:refresh", {
              force: true
            });
          },
          submitStepResponse() {
            const post = this.findAncestorModel();
            const topic = post.topic;
            const learningStep = post.learningStep;
            const learningUnit = topic.learningUnit;
            if (!learningUnit || !post.comment || !learningStep) {
              return null;
            }
            learningUnit.submitStepResponse(learningStep.number, post.id).then(result => {
              if (result && result.success) {
                post.set("response_to_step_id", result.response_to_step_id);
                this.scheduleRerender();
              }
            });
          },
          unsubmitStepResponse() {
            const post = this.findAncestorModel();
            const topic = post.topic;
            const learningStep = post.learningStep;
            const learningUnit = topic.learningUnit;
            if (!learningUnit || !post.comment || !learningStep) {
              return null;
            }
            learningUnit.unsubmitStepResponse(learningStep.number, post.id).then(result => {
              if (result && result.success) {
                post.set("response_to_step_id", null);
                this.scheduleRerender();
              }
            });
          },
          toggleStepMarks() {
            this.state.show_step_marks = !this.state.show_step_marks;
            this.scheduleRerender();
          },
          clickOutside() {
            this.state.show_step_marks = false;
            this.scheduleRerender();
          },
          markStep(mark) {
            const post = this.findAncestorModel();
            const topic = post.topic;
            const learningStep = post.learningStep;
            const learningUnit = topic.learningUnit;
            this.toggleStepMarks();
            learningUnit.markStep(learningStep.number, mark, post.id).then(result => {
              if (result && result.success) {
                post.set("response_mark", result.response_mark);
              }
            });
          },
          forceVisible() {
            const post = this.findAncestorModel();
            const topic = post.topic;
            const learningStep = post.learningStep;
            const learningUnit = topic.learningUnit;
            bootbox.confirm(_I18n.default.t("step.force_visible.confirm"), _I18n.default.t("no_value"), _I18n.default.t("yes_value"), result => {
              if (result) {
                learningUnit.forceStepVisible(learningStep.number).then(r => {
                  if (r && r.success) {
                    topic.postStream.triggerChangedPost(post.id, new Date()).then(() => this.appEvents.trigger("post-stream:refresh", {
                      id: post.id
                    }));
                  }
                });
              }
            });
          }
        });
        api.decorateWidget("post-menu:extra-controls", helper => {
          const post = helper.getModel();
          const topic = post.topic;
          const learningUnit = topic.learningUnit;
          if (!learningUnit) {
            return;
          }
          const learningStep = post.learningStep;
          const canCreatePost = helper.attrs.canCreatePost;
          if (canCreatePost && !post.hidden && learningStep) {
            let replyComment = post.reply_to_post_number;
            let i18nKey = replyComment ? "comment_reply" : "comment";
            let commentBtn = {
              action: "openCommentCompose",
              title: `topic.${i18nKey}.help`,
              icon: replyComment ? "reply" : "comment",
              className: "comment create fade-out"
            };
            if (!helper.attrs.mobileView && !replyComment) {
              commentBtn.label = `topic.${i18nKey}.title`;
            }
            return helper.attach("button", commentBtn);
          }
        });
        api.decorateWidget("post-menu:extra-controls", helper => {
          const post = helper.getModel();
          const learningStep = post.learningStep;
          const learningUnit = post.topic.learningUnit;
          if (!learningUnit || !learningStep) {
            return;
          }
          if (learningStep.can_self_complete) {
            const completed = learningStep.completed;
            const type = completed ? "uncomplete" : "complete";
            const icon = completed ? "undo" : "check";
            const stepBtn = {
              action: `${type}Step`,
              icon,
              className: `user-step create fade-out ${type}`,
              title: `step.${type}.title`,
              titleOptions: {
                stepNumber: learningStep.number
              },
              label: `step.${type}.label`
            };
            return helper.attach("button", stepBtn);
          }
        });
        api.decorateWidget("post-menu:extra-controls", helper => {
          const post = helper.getModel();
          const learningStep = post.learningStep;
          const learningUnit = post.topic.learningUnit;
          if (!learningUnit || !learningStep) {
            return;
          }
          if (post.comment && post.can_submit_as_response) {
            const isResponse = post.response_to_step_id;
            const type = isResponse ? "unsubmit" : "submit";
            const stepBtn = {
              action: `${type}StepResponse`,
              icon: isResponse ? "undo" : "check",
              className: "submit-response",
              title: `step.response.${type}.title`,
              titleOptions: {
                stepNumber: learningStep.number
              },
              label: `step.response.${type}.label`
            };
            return helper.attach("button", stepBtn);
          }
        });
        const markIcons = {
          pass: "check",
          fail: "times",
          change: "far-circle"
        };
        api.decorateWidget("post-menu:extra-controls", helper => {
          const post = helper.getModel();
          const learningStep = post.learningStep;
          const learningUnit = post.topic.learningUnit;
          if (!learningUnit || !learningStep) {
            return;
          }
          if (post.comment && learningStep.can_mark_response) {
            let icon = null;
            let contents = _I18n.default.t("step.mark.show.label");
            if (post.response_mark) {
              contents = learningStep.marks[post.response_mark];
              icon = markIcons[post.response_mark];
            }
            let result = [helper.attach("button", {
              action: "toggleStepMarks",
              icon,
              className: "show-step-marks",
              translatedTitle: _I18n.default.t("step.mark.show.title", {
                stepNumber: learningStep.number
              }),
              contents
            })];
            if (helper.state.show_step_marks && learningStep.marks) {
              let marks = learningStep.marks;
              let stepMarkList = [];
              Object.keys(marks).forEach(mark => {
                stepMarkList.push(helper.attach("link", {
                  className: `step-mark ${mark === post.response_mark ? "current" : ""} ${mark}`,
                  icon: markIcons[mark],
                  action: "markStep",
                  actionParam: mark,
                  rawTitle: marks[mark],
                  rawLabel: marks[mark]
                }));
              });
              result.push(helper.h("div.step-marks", stepMarkList));
            }
            return helper.h("div.step-mark-container", result);
          }
        });
        api.decorateWidget("post-menu:extra-controls", helper => {
          const post = helper.getModel();
          const learningStep = post.learningStep;
          const learningUnit = post.topic.learningUnit;
          if (!learningUnit || !learningStep) {
            return;
          }
          if (post.hidden && !learningStep.visible && !learningStep.force_visible && learningStep.visibility_type === "hidden" && learningStep.visibility_modifier === "until_previous_completed") {
            const forceVisibleBtn = {
              action: "forceVisible",
              icon: "far-eye",
              className: `user-step force-visible fade-out`,
              title: `step.force_visible.title`,
              label: `step.force_visible.label`
            };
            return helper.attach("button", forceVisibleBtn);
          }
        });
        api.decorateWidget("post:after", function (helper) {
          const model = helper.getModel();
          const learningUnit = model.topic.learningUnit;
          if (!learningUnit) {
            return;
          }
          if (model.attachCommentToggle && model.hiddenComments > 0) {
            let type = Number(siteSettings.learning_step_comments_default) > 0 ? "more" : "all";
            return helper.attach("link", {
              action: "showComments",
              actionParam: model.learningStep.post_id,
              rawLabel: _I18n.default.t(`topic.comment.show_comments.${type}`, {
                count: model.hiddenComments
              }),
              className: "show-comments"
            });
          }
        });
        api.reopenWidget("post-meta-data", {
          html() {
            const contents = this._super(...arguments);
            const post = this.findAncestorModel();
            const topic = post.topic;
            const learningStep = post.learningStep;
            if (!topic.learningUnit || !learningStep) {
              return;
            }
            contents.pop();
            if (!post.firstPost && !post.comment) {
              const stepMeta = [(0, _virtualDom.h)("span.step-number", {
                attributes: {
                  title: _I18n.default.t("step.number", {
                    stepNumber: learningStep.number
                  })
                }
              }, _I18n.default.t("step.number", {
                stepNumber: learningStep.number
              })), (0, _virtualDom.h)("span.review-type", {
                attributes: {
                  title: _I18n.default.t(`step.review.${learningStep.review_type}.title`)
                }
              }, _I18n.default.t(`step.review.${learningStep.review_type}.label`))];
              if (learningStep.completed) {
                stepMeta.push((0, _virtualDom.h)("span.step-completed.small", {
                  attributes: {
                    title: _I18n.default.t("step.completed.title")
                  }
                }, _I18n.default.t("step.completed.label")));
              }
              contents.push((0, _virtualDom.h)("div.step-meta", stepMeta));
            }
            if (post.comment) {
              const commentMeta = [];
              if (post.response_to_step_id) {
                let responseType = "user";
                if (currentUser && post.user_id === currentUser.id) {
                  responseType = "your";
                }
                if (currentUser && post.learningStep.marker_user_id === currentUser.id) {
                  responseType = "you_marked";
                }
                commentMeta.push((0, _virtualDom.h)(`span.${responseType.replace(/_/g, "-")}-response`, {
                  attributes: {
                    title: _I18n.default.t(`step.response.${responseType}.title`, {
                      stepNumber: learningStep.number
                    })
                  }
                }, _I18n.default.t(`step.response.${responseType}.label`)));
              }
              if (post.response_mark) {
                commentMeta.push((0, _virtualDom.h)(`span.response-mark.${post.response_mark}`, {
                  attributes: {
                    title: _I18n.default.t("step.response.mark.title", {
                      stepNumber: learningStep.number
                    })
                  }
                }, post.learningStep.marks[post.response_mark]));
              }
              if (commentMeta.length) {
                contents.push((0, _virtualDom.h)("div.comment-meta", commentMeta));
              }
            }
            return contents;
          }
        });
        api.addPostClassesCallback(attrs => {
          if (attrs.learning_unit && !attrs.firstPost) {
            if (attrs.comment) {
              let classes = ["comment"];
              if (attrs.showComment) {
                classes.push("show");
              }
              if (attrs.cooked_hidden) {
                classes.push("hidden-response");
              }
              return classes;
            } else {
              return ["step"];
            }
          }
        });
        api.modifyClass("component:scrolling-post-stream", dt7948.p({
          pluginId: "discourse-learning-management",
          showComments: [],
          setShowComments() {
            const composer = (0, _application.getOwner)(this).lookup("controller:composer");
            const post = composer.get("model.post");
            if (post && post.learningStep) {
              this.set("showComments", [post.id]);
            }
            this._refresh({
              force: true
            });
          },
          didInsertElement() {
            this._super(...arguments);
            this.appEvents.on("composer:opened", this, this.setShowComments);
          },
          willDestroyElement() {
            this._super(...arguments);
            this.appEvents.off("composer:opened", this, this.setShowComments);
          },
          buildArgs() {
            return Object.assign(this._super(...arguments), this.getProperties("showComments"));
          }
        }, [["method", "setShowComments", [_decorators.bind]]]));
        api.reopenWidget("post-stream", {
          buildKey: () => "post-stream",
          firstPost() {
            return this.attrs.posts.toArray()[0];
          },
          defaultState(attrs, state) {
            let defaultState = this._super(attrs, state);
            const firstPost = this.firstPost();
            if (!firstPost || !firstPost.learning_unit) {
              return defaultState;
            }
            defaultState["showComments"] = attrs.showComments;
            return defaultState;
          },
          showComments(postId) {
            let showComments = this.state.showComments;
            if (showComments.indexOf(postId) === -1) {
              showComments.push(postId);
              if (this.state) {
                this.state.showComments = showComments;
                this.appEvents.trigger("post-stream:refresh", {
                  force: true
                });
              }
            }
          },
          html(attrs, state) {
            const firstPost = this.firstPost();
            if (!firstPost || !firstPost.learning_unit) {
              return this._super(...arguments);
            }
            let showComments = state.showComments;
            if (attrs.showComments && attrs.showComments.length) {
              attrs.showComments.forEach(postId => {
                if (!showComments.includes(postId)) {
                  showComments.push(postId);
                }
              });
            }
            let posts = attrs.posts || [];
            let postArray = this.capabilities.isAndroid ? posts : posts.toArray();
            let defaultComments = Number(siteSettings.learning_step_comments_default);
            let commentCount = 0;
            let lastVisible = null;
            postArray.forEach((post, i) => {
              if (!post.topic) {
                return;
              }
              if (post.learningStep && post.comment) {
                commentCount++;
                let showingComments = showComments.indexOf(post.learningStep.post_id) > -1;
                let shownByDefault = commentCount <= defaultComments;
                post["showComment"] = showingComments || shownByDefault;
                post["attachCommentToggle"] = false;
                if (post["showComment"]) {
                  lastVisible = i;
                }
                if ((!postArray[i + 1] || postArray[i + 1].learningStep) && !post["showComment"]) {
                  postArray[lastVisible]["attachCommentToggle"] = true;
                  postArray[lastVisible]["hiddenComments"] = commentCount - defaultComments;
                }
              } else {
                post["attachCommentToggle"] = false;
                commentCount = 0;
                lastVisible = i;
              }
            });
            if (this.capabilities.isAndroid) {
              attrs.posts = postArray;
            } else {
              attrs.posts = _postsWithPlaceholders.default.create({
                posts: postArray,
                store
              });
            }
            return this._super(attrs, state);
          }
        });
        api.modifyClass("model:post-stream", dt7948.p({
          pluginId: "discourse-learning-management",
          learningUnit: (0, _computed.alias)("topic.learning_unit"),
          removeMarkedResponses: (0, _computed.alias)("learningUnit"),
          streamFilters() {
            const result = this._super(...arguments);
            if (this.removeMarkedResponses) {
              result.remove_marked_responses = this.removeMarkedResponses;
            }
            return result;
          },
          getCommentIndex(post) {
            const posts = this.get("posts");
            let passed = false;
            let commentIndex = null;
            posts.some((p, i) => {
              if (passed && !p.reply_to_post_number) {
                commentIndex = i;
                return true;
              }
              if (p.post_number === post.reply_to_post_number && i < posts.length - 1) {
                passed = true;
              }
            });
            return commentIndex;
          },
          insertCommentInStream(post) {
            const stream = this.stream;
            const postId = post.get("id");
            const commentIndex = this.getCommentIndex(post) - 1;
            if (stream.indexOf(postId) > -1 && commentIndex && commentIndex > 0) {
              stream.removeObject(postId);
              stream.insertAt(commentIndex, postId);
            }
          },
          stagePost(post) {
            let result = this._super(...arguments);
            if (!this.learning_unit) {
              return result;
            }
            if (post.get("reply_to_post_number")) {
              this.insertCommentInStream(post);
            }
            return result;
          },
          commitPost(post) {
            let result = this._super(...arguments);
            if (!this.learning_unit) {
              return result;
            }
            if (post.get("reply_to_post_number")) {
              this.insertCommentInStream(post);
            }
            return result;
          },
          prependPost(post) {
            if (!this.learningUnit) {
              return this._super(...arguments);
            }
            const stored = this.storePost(post);
            if (stored) {
              const posts = this.get("posts");
              if (post.post_number === 2 && posts[0].post_number === 1) {
                posts.insertAt(1, stored);
              } else {
                posts.unshiftObject(stored);
              }
            }
            return post;
          },
          appendPost(post) {
            if (!this.learningUnit) {
              return this._super(...arguments);
            }
            const stored = this.storePost(post);
            if (stored) {
              const posts = this.get("posts");
              if (!posts.includes(stored)) {
                let insertPost = () => posts.pushObject(stored);
                if (post.get("reply_to_post_number")) {
                  const commentIndex = this.getCommentIndex(post);
                  if (commentIndex && commentIndex > 0) {
                    insertPost = () => posts.insertAt(commentIndex, stored);
                  }
                }
                if (!this.get("loadingBelow")) {
                  this.get("postsWithPlaceholders").appendPost(insertPost);
                } else {
                  insertPost();
                }
              }
              if (stored.get("id") !== -1) {
                this.set("lastAppended", stored);
              }
            }
            return post;
          }
        }, [["method", "streamFilters", [(0, _decorators.default)("summary", "userFilters.[]", "filterRepliesToPostNumber", "filterUpwardsPostID", "removeMarkedResponses")]]]));
        api.reopenWidget("post-avatar", {
          html(attrs) {
            const learningUnit = attrs.learning_unit;
            if (!learningUnit) {
              return this._super(...arguments);
            }
            if (attrs.comment) {
              this.settings.size = "small";
            } else {
              this.settings.size = "large";
            }
            return this._super(attrs);
          }
        });
        api.reopenWidget("post", {
          html(attrs) {
            if (!attrs.learning_unit) {
              return this._super(...arguments);
            }
            if (attrs.cloaked) {
              return "";
            }
            if (attrs.learningStep) {
              attrs.replyToUsername = null;
            }
            if (!attrs.comment) {
              attrs.replyCount = null;
            }
            return this.attach("post-article", attrs);
          },
          openCommentCompose() {
            this.sendWidgetAction("replyToPost", this.model).then(() => {
              (0, _runloop.next)(this, () => {
                const composer = api.container.lookup("controller:composer");
                if (!composer.model.post) {
                  composer.model.set("post", this.model);
                }
              });
            });
          }
        });
        api.reopenWidget("expand-hidden", {
          html() {
            const post = this.findAncestorModel();
            if (post.learningStep) {
              return "";
            } else {
              return this._super(...arguments);
            }
          }
        });
      });
    }
  };
});